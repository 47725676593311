<script setup>
// Partials
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Toggle from '@/Components/Button/Toggle.vue';
import MediaWithPopup from '@/Components/MediaWithPopup.vue';

// Icons
import block from '@i/blocked-icon-active.svg';
import Unblock from '@i/blocked-icon-inactive.svg';
import star from '@i/star-icon-active.svg';
import Unstar from '@i/star-icon-inactive.svg';
import TinyRating from '@/Components/Flexer/Partials/TinyRating.vue';

// Variables
const { t } = useI18n();
const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    flexer: Object,
    pink: {
        type: Boolean,
        default: true,
    },
    liked: Boolean,
    hidden: Boolean,
    withRelationButtons: {
        type: Boolean,
        default: true,
    },
});

// Flexer Variables
const dateOfBirth = dayjs(props.flexer.date_of_birth);
const city = ref(props.flexer.city);
const phone = ref(props.flexer.phone_number);
const hasDriverLicense = ref(props.flexer.has_drivers_license);
const age = dayjs().diff(dateOfBirth, 'year');
const avgRating = ref(props.flexer.received_reviews_avg_rating);
const receivedReviews = ref(props.flexer.received_reviews_count);
const biography = ref(props.flexer.biography);
const userType = { freelancer: t('Freelancer'), temp_worker: t('Temp Worker') };
const languages = computed(() => {
    let ans = [];
    if (props.flexer.native_tongue?.includes('nl')) {
        ans.push(t('Dutch'));
    }
    if (props.flexer.native_tongue?.includes('en')) {
        ans.push(t('English'));
    }

    return ans.join(', ');
});
</script>

<template>
    <div class="w-full rounded bg-white text-blue">
        <div class="relative flex justify-between">
            <div class="text-base text-blue">
                <MediaWithPopup
                    v-if="flexer.profile_media"
                    :media="flexer.profile_media"
                    classes="object-cover w-[160px] h-[160px] rounded-full mb-8 md:hidden"
                />

                <h1 class="mb-4 pr-45 text-[32px] font-bold leading-[32px]">
                    {{ flexer.first_name }} {{ flexer.last_name }}
                </h1>

                <div class="flex flex-col">
                    <p class="mt-2 flex flex-col">
                        <span class="font-bold">{{ $t('Type') }}</span>
                        <span>{{ userType[flexer.type] }}</span>
                    </p>

                    <p
                        v-if="flexer.native_tongue?.length > 0"
                        class="mt-2 flex flex-col"
                    >
                        <span class="font-bold">{{ $t('Speaks') }}</span>
                        <span>{{ languages }}</span>
                    </p>

                    <p
                        v-if="phone"
                        class="mt-2 flex flex-col"
                    >
                        <span class="font-bold">{{ $t('Phone number') }}</span>
                        <span>{{ phone }}</span>
                    </p>

                    <p
                        v-if="age"
                        class="mt-2 flex flex-col"
                    >
                        <span class="font-bold">{{ $t('Age') }}</span>
                        <span>{{ age + (age && city ? ', ' + city : '') }}</span>
                    </p>

                    <img
                        v-if="hasDriverLicense === true"
                        class="mt-2 h-full w-[18px]"
                        src="@/../img/icon/car.svg"
                    >

                    <p
                        v-if="avgRating"
                        class="mt-2 flex flex-col"
                    >
                        <span class="font-bold">Reviews</span>
                        <TinyRating
                            v-if="avgRating"
                            :class="{ 'text-pink': pink, 'text-gray-500-700': !pink }"
                            :value="avgRating"
                            :review-count="receivedReviews"
                        />
                    </p>
                </div>

                <p
                    v-if="biography"
                    class="mt-8"
                >
                    {{ biography }}
                </p>

                <div
                    v-if="withRelationButtons"
                    class="mt-2 flex"
                >
                    <Toggle
                        :activate-url="route('location.liked-flexers.update', { liked_flexer: flexer.id })"
                        :active="liked"
                        :icon="star"
                        :active-icon="star"
                        :deactivate-url="route('location.liked-flexers.destroy', { liked_flexer: flexer.id })"
                        :inactive-icon="Unstar"
                        activate-method="put"
                        deactivate-method="delete"
                        icon-btn
                    />

                    <Toggle
                        :activate-url="route('location.hidden-flexers.update', { hidden_flexer: flexer.id })"
                        :active="hidden"
                        :icon="block"
                        :active-icon="block"
                        :deactivate-url="route('location.hidden-flexers.destroy', { hidden_flexer: flexer.id })"
                        :inactive-icon="Unblock"
                        activate-method="put"
                        deactivate-method="delete"
                        icon-btn
                    />
                </div>
            </div>
            <div class="">
                <MediaWithPopup
                    v-if="flexer.profile_media"
                    :media="flexer.profile_media"
                    classes="object-cover w-[160px] h-[160px] rounded-full hidden md:block absolute right-0 top-0"
                />
            </div>
        </div>
    </div>
    <slot />
</template>
